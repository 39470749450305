import React from "react";

import {
    CardSidebar,
    Block,
    DateText,
} from "../../components/Sidebar";

import { graphql, Link, useStaticQuery } from "gatsby";
import moment from "moment";
import { getProperty } from "../../utils/helperFn";

const Sidebar = () => {

    const data = useStaticQuery(graphql`
        query getRecentNewsArticles {
            allStrapiNewsArticles {
                nodes {
                title
                slug
                published_at
                }
            }
        }`
    )

    const pageData = getProperty(data, 'allStrapiNewsArticles');

    const sortedPageData = pageData.nodes.sort((a, b) => (new Date(b.published_at).getTime()) - (new Date(a.published_at).getTime()));

    const renderRecentNewsArticles = () => {
        return sortedPageData.slice(0, 4).map((article, i) => (
            <Block css={sortedPageData.length === i + 1 ? "border:none" : "border-color:white"}>
                <Link to={`/news/${article.slug}/`}>
                    <p className="whiteColor">
                        {article.title}
                    </p>
                    <DateText>{moment(article.published_at).format('ll')}</DateText>
                </Link>
            </Block>
        ))
    }

    return (
        <>
            <CardSidebar>
                <h4 className="whiteColor mb-4">Recent Posts</h4>
                {renderRecentNewsArticles()}
            </CardSidebar>
        </>
    );
};
export default Sidebar;
